
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddGRNModal from "@/components/modals/forms/AddGRNModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddGRNModal,
    AddProductListSalesEnquiry,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(false);
    const router = useRouter();
    const searchTearm = ref("");
    const listVisible = ref<boolean>(true);
    const formData = ref({
      startDate: "",
      endDate: "",
      grn_no: "",
    });

    interface WICompanies {
      stock_grn_id: string;
      stock_grn_no: string;
      stock_grn_date: string;
      company_name: string;
      invoice_no: string;
      inword_type: string;
      invoice_date: string;
      po_no: string;
      pi_no: string;
      vehicle_no: string;
      is_approved: boolean
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WICompanies>>([]);

    const getCompanyList = async (data) => {
      //loadingData.value = true;
      var user = JSON.parse(JwtService.getToken());
      var start_data = ""
        var end_date = ""
      if(!formData.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formData.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formData.value.startDate[1]).format("YYYY-MM-DD")
      }
      try {
        var values = {
          user_id: user.user_id,
          start_date: start_data,
          end_date: end_date,
          grn_no: formData.value.grn_no,
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(ActionsFi.CUST_GET_GRN_LIST, values)
          .then(({ data }) => {
            tableData.value = [];

            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j]["is_approved"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }
              resultsM.value = Array({
                stock_grn_id: data.result_list[j]["grn_id"],
                stock_grn_no: data.result_list[j]["grn_no"],
                stock_grn_date: data.result_list[j]["grn_date"],
                company_name: data.result_list[j]["company_name"],
                inword_type: data.result_list[j]["inword_type"],
                invoice_no: data.result_list[j]["invoice_no"],
                invoice_date: data.result_list[j]["invoice_date"],
                po_no: data.result_list[j]["po_no"],
                pi_no: data.result_list[j]["pi_no"],
                vehicle_no: data.result_list[j]["vehicle_no"],
                is_approved: data.result_list[j]["is_approved"] == 0 ? false : true,
                // is_approved: {
                //   label: active_label,
                //   color: active_color,
                // },
              });

              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            // tableData.value = [{
            //   company_id: 1,
            //   user: {
            //     avatar: "string;",
            //     name: "string;",
            //     group: "string;",
            //   },
            //   comp_type: "string;",
            //   status: {
            //     label: "string;",
            //     color: "string;",
            //   },
            //   active: {
            //     label: "string;",
            //     color: "string;,"
            //   },
            //   roc: "string,",
            //   nob: "string,",
            //   doi: "string,",
            //   pan: "string,"
            // }];

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Goods Receipt Note", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].company_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    async function approveGrn(grn_id) {
      var user = JSON.parse(JwtService.getToken());
      let params = {
        grn_id: grn_id,
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_APPROVE_GRN, params)
        .then(({ data }) => {
          getCompanyList(searchTearm.value);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const Pdfdata = async (id) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        "user_id" : user.user_id,
        "doc_type" : 7,
        "doc_id" : id
      };
      console.log("DB DATA")
      console.log(db_data)
      //loading.value = true;
      try {
        await store
          .dispatch(ActionsFi.CUST_PDF_REQUEST, db_data)
          .then(({ data }) => {
           console.log("DATATATATA")
           console.log(data.request_id)
           console.log('https://print.elogicals.com/print/grn/' + db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id)
            //loading.value = false;
            window.open('https://print.elogicals.com/print/grn/' + db_data.doc_id + '/' +db_data.user_id + '/' + data.request_id )
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      showList,
      listVisible,
      hideList,
      formData,
      router,
      approveGrn,
      Pdfdata
    };
  },
});
