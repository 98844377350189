
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { ElTable } from "element-plus";
import moment from "moment";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const tableData = ref<Array<DispatchOrder>>([]);
    const tnListData = ref<Array<TransportList>>([]);
    const inward_type_data = ref([]);
    const supplier_data = ref([]);
    const branch_data = ref([]);

    const consignee_data = ref([]);
    const divert_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<DispatchOrder[]>([]);

    const multipleTableTnRef = ref<InstanceType<typeof ElTable>>();
    const multipleTnSelection = ref<TransportList[]>([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();
    const dialogSelectProductTableVisible = ref(false);
    const addPlanningIndentRef = ref<null | HTMLElement>(null);

    // getAddData();
    // getUOM();
    getCompanyList();
    getInwardTypes();
    getTnList();

    interface DispatchOrder {
      stock_dispatch_order_details_id: string;
      stock_purchase_order_no: string;
      product_name: string;
      product_qunatity: string;
      dispatch_quantity: string;
      consignee_name: string;
      consignee_warehouse: string;
    }

    interface TransportList {
      stock_transport_note_id: string;
      stock_transport_note_no: string;
      vehicle_no: string;
      lr_no: string;
      lr_date: string;
      driver_name: string;
      driver_mobile: string;
    }
    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    const formData = ref({
      supplier_select: "",
      supplier_branch_select: "",
      transport: "",
      inward_type_select: 1,
      divert_select: 0,
      invoice_date: "",
      invoice_no: "",
      dc_no: "",
      dc_date: "",
      grn_date: new Date(),
    });

    const rules = ref({
      supplier_select: [
        {
          required: true,
          message: "Supplier is required",
          trigger: "change",
        },
      ],
      supplier_branch_select: [
        {
          required: true,
          message: "Supplier Branch is required",
          trigger: "change",
        },
      ],
      transport: [
        {
          required: true,
          message: "Transport No is required",
          trigger: "change",
        },
      ],
      inward_type_select: [
        {
          required: true,
          message: "Inward Type is required",
          trigger: "change",
        },
      ],
      invoice_date: [
        {
          required: true,
          message: "Invoice Date is required",
          trigger: "change",
        },
      ],
      invoice_no: [
        {
          required: true,
          message: "Invoice No is required",
          trigger: "change",
        },
      ],
      // dc_no: [
      //   {
      //     required: true,
      //     message: "DC No is required",
      //     trigger: "change",
      //   },
      // ],
      // dc_date: [
      //   {
      //     required: true,
      //     message: "DC Date is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      let product_list = multipleSelection.value.map((item) => {
        return {
          stock_purchase_order_details_id: item.stock_dispatch_order_details_id,
          dispatch_quantity: item.stock_purchase_order_no,
        };
      });
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One PO.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        supplier_company_id: data.supplier_select,
        supplier_company_branch_id: data.supplier_branch_select,
        stock_transport_note_id:
          multipleTnSelection.value[0].stock_transport_note_id,
        grn_inword_type_id: data.inward_type_select,
        is_divert: data.divert_select,
        invoice_no: data.invoice_no,
        invoice_date: moment(data.invoice_date).format("YYYY-MM-DD"),
        dc_no: data.dc_no,
        dc_date: moment(data.dc_date).format("YYYY-MM-DD"),
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post("https://elogicalservices.mymetal.in/mm_grn/create", db_data, {
            headers: {
              "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
            },
          })
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPlanningIndentRef.value);
                emit("refreshList");
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getWarehouseList() {
      let values = {
        company_id: formData.value["supplier_select"],
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          branch_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCompanyList() {
      //trade type 1 for Supplier
      let values = { trade_type: 1 };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_LIST, values)
        .then(({ data }) => {
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTableData() {
      debugger;
      let params = {
        supplier_company_id: formData.value["supplier_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PENDING_DISPATCH_ORDER_BY_ID_LIST, params)
        .then(({ data }) => {
          debugger;
          data = data.map((dispatchOrder) => {
            let value = {
              stock_dispatch_order_details_id:
                dispatchOrder.stock_dispatch_order_details_id,
              stock_purchase_order_no: dispatchOrder.stock_purchase_order_no,
              product_name: dispatchOrder.product_name,
              product_qunatity: dispatchOrder.product_qunatity,
              dispatch_quantity: dispatchOrder.dispatch_quantity,
              consignee_name: dispatchOrder.consignee_name,
              consignee_warehouse: dispatchOrder.consignee_warehouse,
            };

            return value;
          });
          tableData.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getInwardTypes() {
      await store
        .dispatch(ActionsFi.CUST_GET_INWARD_TYPE_LIST)
        .then(({ data }) => {
          inward_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTnList() {
      await store
        .dispatch(ActionsFi.CUST_GET_TRANSPORT_NO_LIST)
        .then(({ data }) => {
          tnListData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const handleSelectionChange = (val: DispatchOrder[]) => {
      multipleSelection.value = val;
    };

    const handleSelectionTnChange = (val: TransportList[]) => {
      debugger;

      let index = val.length != 0 ? val.length - 1 : 0;
      var rowValue = val[index];
      multipleTnSelection.value = [];
      multipleTnSelection.value.push(rowValue);
      val.forEach((row) => {
        if (row === rowValue) {
          multipleTableTnRef.value!.toggleRowSelection(row, true);
        } else {
          multipleTableTnRef.value!.toggleRowSelection(row, false);
        }
      });
    };
    const onContinueTn = () => {
      debugger;
      if (multipleTnSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Transport",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        formData.value["transport"] =
          multipleTnSelection.value[0].stock_transport_note_no;
        dialogSelectProductTableVisible.value = false;
      }
    };

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      getTableData,
      getInwardTypes,
      getCompanyList,
      supplier_data,
      addPlanningIndentRef,
      consignee_data,
      formRef,
      tableData,
      loading,
      dialogSelectProductTableVisible,
      addCompanyModalRef,
      uom_data,
      tnListData,
      inward_type_data,
      multipleTableRef,
      handleSelectionChange,
      multipleSelection,
      multipleTableTnRef,
      divert_data,
      branch_data,
      multipleTnSelection,
      handleSelectionTnChange,
      getTnList,
      onContinueTn,
      getWarehouseList,
    };
  },
});
